import React from 'react';

import styles from './DefMobilePage.module.scss';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';

import Header from '@components/Header/Header';
import Preloader from '@components/UI/Preloader/Preloader';
import { Footer } from '@components/Footer/Footer';
import DefComp from '@components/DefComp/DefComp';

interface IProps {
  isLoading: boolean;
}

const DefMobilePage: React.FC<IProps> = ({ isLoading }) => {
  return (
    <main className={styles.page}>
      <Header />
      {isLoading ? <Preloader /> : <DefComp />}
      <Footer />
    </main>
  );
};

export default ProtectedRoute(DefMobilePage);
