import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchPOSTData } from 'api';

type NotificationType = { UID: string };

interface notificationState {
  notificationArr: NotificationType[];
  notificationsNumber: number;
  isNotificationLoading: boolean;
  updateInterval: number;
}

const initialState: notificationState = {
  notificationArr: [],
  notificationsNumber: 0,
  isNotificationLoading: false,
  updateInterval: 0,
};

const NotificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationSliceIsLoading(state, action: PayloadAction<boolean>) {
      return { ...state, isLoading: action.payload };
    },
    addNotificationsNumber(state, action: PayloadAction<{ Number: number }>) {
      return { ...state, notificationsNumber: action.payload.Number };
    },
    addNotificationsArr(state, action: PayloadAction<NotificationType[]>) {
      return {
        ...state,
        notificationArr: action.payload,
        notificationsNumber: action.payload.length,
      };
    },
    removeNotification(state, action: PayloadAction<string>) {
      const newArr = state.notificationArr.filter((item) => item.UID !== action.payload);
      return {
        ...state,
        notificationArr: newArr,
        notificationsNumber: newArr.length,
      };
    },
    setNotificationErrorMessage(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },
    setUpdateInterval(state, action: PayloadAction<number>) {
      localStorage.setItem('updateInterval', `${action.payload}`);
      return { ...state, updateInterval: action.payload };
    },
  },
});

export const {
  setNotificationSliceIsLoading,
  addNotificationsNumber,
  addNotificationsArr,
  removeNotification,
  setNotificationErrorMessage,
  setUpdateInterval,
} = NotificationSlice.actions;

export default NotificationSlice.reducer;

// export const fetchNotificationsNumber = createAsyncThunk(
//   'notifications/fetchNotifications',
//   async (_, { dispatch }) => {
//     await fetchPOSTData(
//       'NumberNewMessages',
//       dispatch,
//       addNotificationsNumber,
//       setNotificationErrorMessage
//     );
//   }
// );

// export const fetchNotificationsNumber = createAsyncThunk(
//   'notifications/fetchNotifications',
//   (_, { dispatch }) => {
//     fetchDataPostSimple('NumberNewMessages')
//       .then((res) => dispatch(addNotificationsNumber(res)))
//       .catch((err) => dispatch(setNotificationErrorMessage(err.message)));
//   }
// );

export const fetchNotificationsArr = createAsyncThunk(
  'notifications/fetchNotifications',
  async (_, { dispatch }) => {
    await fetchPOSTData('newmessages', dispatch, addNotificationsArr, setNotificationErrorMessage);
  }
);
