import React, { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { emptyPass } from '@shared/constants';

function ProtectedRoute<T>(Component: React.ComponentType<T>) {
  // eslint-disable-next-line react/display-name
  return (props: T) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      const { pass } = JSON.parse(localStorage.getItem('userInfo') || emptyPass);
      !pass && navigate('/auth');
      !pass && window.location.reload();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Component {...props!} />;
  };
}

export default ProtectedRoute;
