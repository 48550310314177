import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './NotificationBar.module.scss';
import { AppDispatch, RootState } from '@redux/store';
import { AppType } from '@shared/types';
import { fetchNotificationsArr, removeNotification } from '@redux/slices/notificationSlice';

import { NotificationCard } from '@components/UI/Card';
import Preloader from '@components/UI/Preloader/Preloader';
import { SimpleBigButton } from '@components/UI/Button';
import { fetchDataPostSimple } from 'api';

const NotificationBar = () => {
  const { appsArr, isAppsLoading } = useSelector((state: RootState) => state.apps);
  const { notificationArr, isNotificationLoading, notificationsNumber } = useSelector(
    (state: RootState) => state.notifications
  );
  const [currTasks, setCurrTasks] = useState<AppType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchNotificationsArr());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const result = appsArr.filter((item) => notificationArr.some((v) => v.UID === item.UID));
    setCurrTasks(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsNumber, notificationArr]);

  const handleReadAllNotifications = () => {
    setIsLoading(true);
    fetchDataPostSimple('SelectAll')
      .then(() => dispatch(fetchNotificationsArr()))
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className={styles.info}>
      {currTasks.length !== 0 && (
        <div className={styles.heading}>
          <SimpleBigButton
            text={'Отметить все как прочитанные?'}
            disabled={false}
            handleClick={handleReadAllNotifications}
          />
        </div>
      )}

      <div className={styles.cards}>
        {currTasks.length === 0 ? (
          isAppsLoading || isNotificationLoading || isLoading ? (
            <Preloader />
          ) : (
            <div className={styles.heading}>
              <p className={styles.text}>Уведомлений нет</p>
            </div>
          )
        ) : (
          currTasks.map((item) => (
            <NotificationCard
              key={item.UID}
              cardInfo={item}
              UID={item.UID}
              notificationText={'Новый комментарий'}
              onClickHandler={() => dispatch(removeNotification(item.UID))}
            />
          ))
        )}
      </div>

      {/* {filterState.isOpen && <ModalAppFilter />} */}
    </div>
  );
};

export default NotificationBar;
