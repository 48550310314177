import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import styles from './SubHeader.module.scss';
import { ServiceType } from '@shared/types';
import { AppDispatch, RootState } from '@redux/store';
import { setFilterService } from '@redux/slices/filterSlices';

import { ServiceName, ServiceShowName } from '@shared/constants';

interface IProps {
  pageName?: string;
  isCreateNewApp?: boolean;
  isAppFiles?: boolean;
  onAttLogoClick?: () => void;
  isEditAppPage?: string;
  editAppHandler?: () => void;
  isEditProfilePage?: boolean;
  editProfileHandler?: () => void;
  deleteApp?: boolean;
  deleteAppHandler?: () => void;
}

const SubHeader: React.FC<IProps> = ({
  pageName = '',
  isCreateNewApp = false,
  isAppFiles = false,
  onAttLogoClick = () => console.log(),
  isEditAppPage = '',
  editAppHandler = () => console.log(),
  isEditProfilePage = false,
  editProfileHandler = () => console.log(),
  deleteApp = false,
  deleteAppHandler = () => console.log(),
}) => {
  const servArr = useSelector((state: RootState) => state.apps.servicesArr);
  const { servFilter } = useSelector((state: RootState) => state.filter);
  const [servicesArr, setServicesArr] = useState<ServiceType[]>(servArr);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currService, setCurrService] = useState<ServiceType>(servFilter);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleSelectService = (v: ServiceType) => {
    setCurrService(v);
    dispatch(setFilterService(v));
  };

  useEffect(() => {
    setCurrService(servFilter);
  }, [servFilter]);

  useEffect(() => {
    setServicesArr(servArr);
  }, [servArr]);

  return (
    <section className={styles.header}>
      <h2 className={styles.title}>{pageName}</h2>
      {servicesArr.length > 1 && (
        <div className={styles.servicesButton}>
          <p className={styles.servName}>{currService.Name || ServiceName}</p>
          <span className={styles.servEllipsis} onClick={() => setModalOpen(true)} />
          {!!servicesArr.length && (
            <div className={cn(styles.servModal, isModalOpen && styles.servModal_opened)}>
              {isModalOpen && (
                <>
                  <div className={styles.modalHeading}>
                    <p className={styles.modalTitle}>{ServiceShowName}</p>
                    <span className={styles.modalClose} onClick={() => setModalOpen(false)} />
                  </div>
                  <div
                    className={styles.modalItem}
                    onClick={() => handleSelectService({ UID: '', Name: '' })}
                  >
                    <input
                      type="radio"
                      className={styles.modalCheckbox}
                      checked={currService.UID === ''}
                      readOnly
                    />
                    <p className={styles.modalSubtitle}>Выбрать всё</p>
                  </div>
                  {servicesArr.map((item) => (
                    <div
                      className={styles.modalItem}
                      onClick={() => handleSelectService(item)}
                      key={item.UID}
                    >
                      <input
                        type="radio"
                        className={styles.modalCheckbox}
                        checked={currService.UID === item.UID}
                        readOnly
                      />
                      <p className={styles.modalSubtitle}>{item.Name}</p>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      )}

      <div className={styles.buttons}>
        {isEditProfilePage && <span className={styles.additional} onClick={editProfileHandler} />}
        {isCreateNewApp && (
          <span className={styles.new} onClick={() => navigate('/applications/new-application')} />
        )}
        {/* {!!isEditAppPage && (
          <SimpleBigButton
            text={isEditAppPage === 'edit' ? 'Редактировать' : 'Сохранить'}
            handleClick={editAppHandler}
          />
        )} */}
        {!!isEditAppPage && (
          <span
            className={isEditAppPage === 'edit' ? styles.edit : styles.save}
            onClick={editAppHandler}
          />
        )}
        {isAppFiles && <span className={styles.attachment} onClick={onAttLogoClick} />}
        {deleteApp && <span className={styles.delete} onClick={deleteAppHandler} />}
      </div>
    </section>
  );
};

export default SubHeader;
