import React, { ReactNode, useState } from 'react';
// import { CategoryType, DepartmentType, OrganizationType, ServiceType } from '@shared/types';
import { useNavigate } from 'react-router';

interface IUserProps {
  UID: string;
  FullName: string;
  Name: string;
  // Organization: OrganizationType;
  // Department: DepartmentType;
  // Position: string;
  Email: string;
  // Service: ServiceType;
  // Category: CategoryType;
  // Address: string;
  // canCreate: boolean;
  // CanCreateInitiators: boolean;
  IsCoordinator: boolean;
  IsExecutor: boolean;
  IsSeniorCoordinator: boolean;
  IsSeniorExecutor: boolean;
  Avatar: string;
}

export type authContextType = {
  isLogged: boolean;
  authLogged: boolean;
  userInfo: IUserProps;
  login: (v: IUserProps) => void;
  logout: () => void;
  handleLogin: () => void;
  updateAvatarHandler: (v: string) => void;
};

const DefaultUserProps = {
  UID: '',
  FullName: '',
  Name: '',
  // Organization: { UID: '', Name: '' },
  // Department: { UID: '', Name: '' },
  // Position: '',
  Email: '',
  // Service: { UID: '', Name: '' },
  // Category: { UID: '', Name: '' },
  // Address: '',
  // canCreate: false,
  // CanCreateInitiators: false,
  Avatar: '',
  IsCoordinator: false,
  IsExecutor: false,
  IsSeniorCoordinator: false,
  IsSeniorExecutor: false,
};

export const authContextDefaultValues: authContextType = {
  isLogged: false,
  authLogged: false,
  userInfo: DefaultUserProps,
  login: () => {},
  logout: () => {},
  handleLogin: () => {},
  updateAvatarHandler: () => {},
};

export const AuthContext = React.createContext<authContextType>(authContextDefaultValues);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [isLogged, setIsLogged] = useState(authContextDefaultValues.isLogged);
  const [authLogged, setAuthLogged] = useState(authContextDefaultValues.authLogged);
  const [userInfo, setUserInfo] = useState(authContextDefaultValues.userInfo);
  const navigate = useNavigate();

  const login = (v: any) => {
    const {
      UID,
      FullName,
      Name,
      Email,
      // Organization,
      // Department,
      // Position,
      // Service,
      // Category,
      // Address,
      // canCreate,
      // CanCreateInitiators,
      Avatar,
      IsCoordinator,
      IsExecutor,
      IsSeniorCoordinator,
      IsSeniorExecutor,
    } = v;
    setUserInfo({
      UID,
      FullName,
      Name: Name ? Name : '',
      // Organization: Organization ? Organization : { UID: '', Name: '' },
      // Department: Department ? Department : { UID: '', Name: '' },
      // Position: Position ? Position : '',
      Email,
      // Service: Service ? Service : { UID: '', Name: '' },
      // Category: Category ? Category : { UID: '', Name: '' },
      // Address: Address ? Address : '',
      // canCreate,
      // CanCreateInitiators,
      Avatar,
      IsCoordinator,
      IsExecutor,
      IsSeniorCoordinator,
      IsSeniorExecutor,
    });
    setIsLogged(true);
  };

  const handleLogin = () => {
    setAuthLogged(true);
  };

  const logout = () => {
    setIsLogged(false);
    setAuthLogged(false);
    setUserInfo(DefaultUserProps);
    localStorage.setItem('userInfo', JSON.stringify({ pass: '' }));
    localStorage.removeItem('appFilters');
    localStorage.removeItem('updateInterval');
    navigate('/auth');
  };

  const updateAvatarHandler = (v: string) => {
    setUserInfo({ ...userInfo, Avatar: v });
  };

  const value = {
    isLogged,
    authLogged,
    userInfo,
    login,
    logout,
    handleLogin,
    updateAvatarHandler,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
