import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppType } from '@shared/types';
import { TaskStatus } from '@shared/enums';

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 360px;
  height: 300px;
`;

const StyledItem = styled.div`
  position: absolute;
  border-radius: 50%;
  background-color: ${(props) => props.theme.backgroundColor};
  width: calc(100px + ${(props) => Math.round(props.theme.value / 2)}px);
  height: calc(100px + ${(props) => Math.round(props.theme.value / 2)}px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    left: -5px;
    right: -5px;
    top: -5px;
    bottom: -5px;
    border: 1px solid ${(props) => props.theme.backgroundColor};
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: rotation 5s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const StyledItem1 = styled(StyledItem)`
  top: calc(50% - 150px);
  left: calc(50% - 110px);
`;

const StyledItem2 = styled(StyledItem)`
  top: calc(50% - 55px);
  left: calc(50% + 20px);
`;

const StyledItem3 = styled(StyledItem)`
  top: calc(50% - 00px);
  left: calc(50% - 130px);
`;

const StyledHeading = styled.h4`
  font-family: Roboto;
  font-size: calc(20px + ${(props) => Math.round(props.theme.value / 10)}px);
  font-style: normal;
  font-weight: 400;
  line-height: calc(22px + ${(props) => Math.round(props.theme.value / 10)}px);
  margin: 0;
  padding: 0;
  color: #fff;
`;

const StyledSubheading = styled.p`
  color: #fff;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.324px;
`;

interface IProps {
  options: AppType[];
}

const RoundedReport = ({ options }: IProps) => {
  const [newPercent, setNewArr] = useState(0);
  const [newNumber, setNewNumber] = useState(0);
  const [progsPercent, setProgsArr] = useState(0);
  const [progsNumber, setProgsNumber] = useState(0);
  const [donePercent, setDonePercent] = useState(0);
  const [doneNumber, setDoneNumber] = useState(0);

  useEffect(() => {
    if (options.length) {
      const newArr = options.filter((item) => item.Status === TaskStatus['Await']).length;
      const progArr = options.filter((item) => item.Status === TaskStatus['InProgs']).length;
      const doneArr = options.filter((item) => item.Status === TaskStatus['Done']).length;
      const appArr = options.length;
      setNewNumber(newArr);
      setNewArr(Math.round((newArr / appArr) * 100));
      setProgsNumber(progArr);
      setProgsArr(Math.round((progArr / appArr) * 100));
      setDoneNumber(doneArr);
      setDonePercent(Math.round((doneArr / appArr) * 100));
    }
  }, [options]);

  return (
    <StyledContainer>
      <StyledItem1 theme={{ value: newPercent, backgroundColor: '#fabb18' }}>
        <StyledHeading theme={{ value: newPercent }}>{newPercent}%</StyledHeading>
        <StyledSubheading>Ожидание</StyledSubheading>
        <StyledSubheading>{newNumber}</StyledSubheading>
      </StyledItem1>
      <StyledItem2 theme={{ value: progsPercent, backgroundColor: '#378dfe' }}>
        <StyledHeading theme={{ value: progsPercent }}>{progsPercent}%</StyledHeading>
        <StyledSubheading>В работе</StyledSubheading>
        <StyledSubheading>{progsNumber}</StyledSubheading>
      </StyledItem2>
      <StyledItem3 theme={{ value: donePercent, backgroundColor: '#91bd13' }}>
        <StyledHeading theme={{ value: donePercent }}>{donePercent}%</StyledHeading>
        <StyledSubheading>Выполнено</StyledSubheading>
        <StyledSubheading>{doneNumber}</StyledSubheading>
      </StyledItem3>
    </StyledContainer>
  );
};

export default RoundedReport;
