import React, { useContext } from 'react';
import cn from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styles from './Header.module.scss';
import { AuthContext } from '@contexts/CurrUserContext';
import { RootState } from '@redux/store';
import defAvatar from '@assets/header/desktop/header_profile_desktop.svg';

interface IProps {
  isModal?: boolean;
  handleOpenModal?: () => void;
  pageName?: string;
  deskPageName?: string;
  isAppFiles?: boolean;
  onAttLogoClick?: () => void;
  isEdit?: boolean;
}

const Header: React.FC<IProps> = ({
  isModal = false,
  handleOpenModal,
  pageName = '',
  deskPageName = '',
  isAppFiles = false,
  onAttLogoClick = () => console.log(),
  isEdit = false,
}) => {
  const navigate = useNavigate();
  const { isLogged, logout, userInfo } = useContext(AuthContext);
  const { notificationsNumber } = useSelector((state: RootState) => state.notifications);

  return (
    <header className={styles.header}>
      {pageName ? (
        <div className={styles.heading}>
          <span className={styles.arrow} onClick={isModal ? handleOpenModal : () => navigate(-1)} />
          <h2 className={styles.title}>{pageName}</h2>
        </div>
      ) : deskPageName ? (
        <h2 className={styles.subtitle}>{deskPageName}</h2>
      ) : (
        <span className={styles.logo} />
      )}
      <div className={styles.attr_desk}>
        <Link to={'/notifications'} className={styles.link}>
          <span
            className={cn(styles.notification, !!notificationsNumber && styles.notification_active)}
          />
          <p className={styles.text}>Уведомления</p>
        </Link>
        <Link to={'/profile'} className={styles.link}>
          <img
            className={styles.profile}
            src={userInfo.Avatar || defAvatar}
            alt="user avatar"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = defAvatar;
            }}
          />
          <p className={styles.text}>Профиль</p>
        </Link>
        <div className={styles.link} onClick={logout}>
          <span className={styles.exit} />
          <p className={styles.text}>Выйти</p>
        </div>
      </div>
      <div className={styles.attr_mobile}>
        {isLogged && (
          <Link
            to={'/notifications'}
            className={cn(styles.notification, notificationsNumber && styles.notification_active)}
          />
        )}
        {isAppFiles && <span className={styles.attachment} onClick={onAttLogoClick} />}
        {isEdit && <span className={styles.edit} onClick={handleOpenModal} />}
      </div>
    </header>
  );
};

export default React.memo(Header);
