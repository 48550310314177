import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import styles from './Users.module.scss';
import { RootState } from '@redux/store';
import { InitiatorsType } from '@shared/types';

import Preloader from '@components/UI/Preloader/Preloader';
import { ChatUser, NewChatUser } from '@components/UI/ChatUser';
import { ModalUsersFilter } from '@components/Modal/ModalUsersFilter/ModalUsersFilter';
import { AuthContext } from '@contexts/CurrUserContext';
import FilterInput from '@components/UI/FilterInput';

const Users = () => {
  const { userInfo } = useContext(AuthContext);
  const { initiatorsArr, isAppsLoading, isInitiatorsArrRefreshing } = useSelector(
    (state: RootState) => state.apps
  );
  const [usersArrList, setUsersArrList] = useState<InitiatorsType[]>(initiatorsArr);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setUsersArrList(initiatorsArr);
  }, [initiatorsArr]);

  const handleSortTaskByDesc = (evt: React.ChangeEvent<HTMLInputElement>) =>
    evt.target.value
      ? setUsersArrList(
          initiatorsArr?.filter((user) =>
            user.Name.toLowerCase().includes(evt.target.value.toLowerCase())
          )
        )
      : setUsersArrList(initiatorsArr);

  const handleFilterUsers = (v: any) => {
    const { currDepartment, currOrg } = v;
    setUsersArrList(
      initiatorsArr
        .filter((item) => (currDepartment.Name ? item.Department.UID === currDepartment.UID : item))
        .filter((item) => (currOrg.Name ? item.Organization.UID === currOrg.UID : item))
    );
  };

  const handleReverseArr = () =>
    setUsersArrList(
      usersArrList
        .slice(0)
        .reverse()
        .map((element) => {
          return element;
        })
    );

  return (
    <div className={styles.info}>
      <FilterInput onChange={handleSortTaskByDesc} />
      <div className={styles.heading}>
        <div className={styles.item} onClick={() => setIsFilterModalOpen(true)}>
          <span className={styles.filters} />
          <p className={styles.text}>Фильтры</p>
        </div>
        <div className={styles.item} onClick={handleReverseArr}>
          <span className={styles.sorting} />
          <p className={styles.text}>Сортировка</p>
        </div>
      </div>
      <div className={styles.body}>
        {userInfo.IsSeniorCoordinator && <NewChatUser />}

        {isAppsLoading || isInitiatorsArrRefreshing ? (
          <Preloader />
        ) : usersArrList.length === 0 ? (
          <p className={styles.text}>Пользователей нет</p>
        ) : (
          usersArrList.map((item) => (
            <ChatUser
              key={item.UID}
              imgLink={''}
              userName={item.FullName || item.Name || 'Иванов И.О.'}
              jobTitle={item.Position || 'Должность'}
              department={item.Department?.Name || 'Отдел'}
              selected={false}
              onClick={() => navigate(`/users/${item.UID}`)}
            />
          ))
        )}
      </div>
      {isFilterModalOpen && (
        <ModalUsersFilter
          isOpen={isFilterModalOpen}
          handleFilterValues={handleFilterUsers}
          handleResetFilters={() => setUsersArrList(initiatorsArr)}
          handleCloseModal={() => setIsFilterModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Users;
