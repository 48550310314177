import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';

import { AppDispatch, RootState } from '@redux/store';
import styles from './Applications.module.scss';
import { AppType } from '@shared/types';
import { handleFilterTasks } from '@shared/hooks';
import { TaskStatus } from '@shared/enums';
import {
  resetFilerStatus,
  setFilterModalState,
  setFilterOwned,
  setFilterStatus,
} from '@redux/slices/filterSlices';
import { removeNotification } from '@redux/slices/notificationSlice';

import { Card } from '@components/UI/Card';
import Preloader from '@components/UI/Preloader/Preloader';
import { ModalAppFilter } from '@components/Modal/ModalAppFilter/ModalAppFilter';
import FilterInput from '@components/UI/FilterInput';
import ExtendButton from '@components/UI/ExtendButton';

export const Applications = () => {
  const { application } = useParams();
  const { appsArr, isAppsLoading, isAppsRefreshing } = useSelector(
    (state: RootState) => state.apps
  );
  const {
    isOpen,
    isActive,
    servFilter,
    categoryFilter,
    statusFilter,
    dateRangeFilter,
    userFilter,
    initiatorFilter,
    coordinatorFilter,
    isWithoutExecutor,
  } = useSelector((state: RootState) => state.filter);

  const [currTasks, setCurrTasks] = useState<AppType[]>(appsArr);
  const dispatch = useDispatch<AppDispatch>();

  // обновление списка заявок при применении фильтра (всех кроме открытия и закрытия модального окна)
  useEffect(() => {
    dispatch(setFilterModalState(false));
    setCurrTasks(
      handleFilterTasks(
        {
          servFilter,
          categoryFilter,
          statusFilter,
          dateRangeFilter,
          userFilter,
          initiatorFilter,
          coordinatorFilter,
          isWithoutExecutor,
        },
        appsArr
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    servFilter,
    categoryFilter,
    statusFilter,
    dateRangeFilter,
    userFilter,
    initiatorFilter,
    coordinatorFilter,
    isWithoutExecutor,
    appsArr,
  ]);

  // useEffect(() => {
  //   setCurrTasks(appsArr);
  //   // dispatch(setFilterModalState(false));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [appsArr]);

  const handleSortTaskByDesc = (evt: React.ChangeEvent<HTMLInputElement>) =>
    evt.target.value
      ? setCurrTasks(
          appsArr?.filter(
            (task) =>
              task.Subject?.toLowerCase().includes(evt.target.value.toLowerCase()) ||
              task.Number.indexOf(evt.target.value) !== -1
          )
        )
      : setCurrTasks(appsArr);

  const handleReverseArr = () =>
    setCurrTasks(
      currTasks
        .slice(0)
        .reverse()
        .map((element) => {
          return element;
        })
    );

  // const handleSortAppsWithoutExecutor = (v: boolean) => {
  //   setAppsWithoutExecutor(v);
  //   dispatch(setFilterOwned(v));
  //   return v
  //     ? setCurrTasks(currTasks.filter((item) => !item.Executor?.UID))
  //     : setCurrTasks(handleFilterTasks(filterState, appsArr));
  // };

  const handleOnClickByFilters = (v: 'free' | 'Appointed' | 'InProgs') => {
    dispatch(resetFilerStatus());
    switch (v) {
      case 'free':
        dispatch(setFilterOwned(!isWithoutExecutor));
        return;
      case 'Appointed':
        dispatch(setFilterOwned(false));
        dispatch(setFilterStatus(TaskStatus['Appointed']));
        return;
      default:
        dispatch(setFilterOwned(false));
        dispatch(setFilterStatus(TaskStatus['InProgs']));
        return;
    }
  };

  return (
    <div className={cn(styles.info, isOpen && styles.info_blocked)}>
      <FilterInput onChange={handleSortTaskByDesc} />
      <div className={styles.heading}>
        <div className={styles.item} onClick={() => dispatch(setFilterModalState(true))}>
          <span className={cn(styles.filters, isActive && styles.filters_active)} />
          <p className={styles.text}>Фильтры</p>
        </div>
        <div className={styles.item} onClick={handleReverseArr}>
          <span className={styles.sorting} />
          <p className={styles.text}>Сортировка</p>
        </div>
      </div>

      <div className={styles.tagContainer}>
        <ScrollContainer className={styles.tags} vertical={false}>
          <button
            className={cn(styles.tag, isWithoutExecutor && styles.tag_active)}
            onClick={() => handleOnClickByFilters('free')}
          >
            Свободные
          </button>
          <button
            className={cn(
              styles.tag,
              statusFilter.includes(TaskStatus['Appointed']) && styles.tag_active
            )}
            onClick={() => handleOnClickByFilters('Appointed')}
          >
            Новая
          </button>
          <button
            className={cn(
              styles.tag,
              statusFilter.includes(TaskStatus['InProgs']) && styles.tag_active
            )}
            onClick={() => handleOnClickByFilters('InProgs')}
          >
            В работе
          </button>
        </ScrollContainer>
      </div>

      <div className={styles.cards}>
        {appsArr.length === 0 || currTasks.length === 0 ? (
          isAppsLoading ? (
            <Preloader />
          ) : (
            <p className={styles.text}>Заявок нет</p>
          )
        ) : (
          currTasks.map((item) => (
            <Card
              key={item.UID}
              cardInfo={item}
              UID={item.UID}
              isActive={item.UID === application}
              onClickHandler={() => dispatch(removeNotification(item.UID))}
            />
          ))
        )}
      </div>

      {isOpen && <ModalAppFilter />}
      {isAppsRefreshing && (
        <div className={styles.preloader}>
          <Preloader />
        </div>
      )}
    </div>
  );
};

export const ApplicationsModal = ({
  isAppListOpen,
  setAppListOpen,
}: {
  isAppListOpen: boolean;
  setAppListOpen: (v: boolean) => void;
}) => {
  return (
    <div className={styles.appContainer}>
      {/* <span
        className={cn(styles.extendButton, isAppListOpen && styles.extendButton_active)}
        onClick={() => setAppListOpen(!isAppListOpen)}
      /> */}
      <ExtendButton
        onClick={() => setAppListOpen(!isAppListOpen)}
        direction={isAppListOpen ? 'left' : 'right'}
        backColor="#fff"
        className={cn(styles.extendButton, isAppListOpen && styles.extendButton_active)}
      />
      <div className={cn(styles.applications, isAppListOpen && styles.applications_open)}>
        <Applications />
      </div>
    </div>
  );
};
