import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppType, InitiatorsType, ServiceType, UserType } from '@shared/types';
import { fetchDataPostSimple, fetchPOSTData } from 'api';

interface appsState {
  isAppsLoading: boolean;
  isAppsRefreshing: boolean;
  isInitiatorsArrRefreshing: boolean;
  appsArr: AppType[];
  servicesArr: ServiceType[];
  coordinatorsArr: UserType[];
  executorsArr: UserType[];
  initiatorsArr: InitiatorsType[];
  errorMessage: string;
}

const initialState: appsState = {
  isAppsLoading: true,
  isAppsRefreshing: false,
  isInitiatorsArrRefreshing: false,
  appsArr: [],
  servicesArr: [],
  coordinatorsArr: [],
  executorsArr: [],
  initiatorsArr: [],
  errorMessage: '',
};

const AppsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    setAppsSliceIsLoading(state, action: PayloadAction<boolean>) {
      return { ...state, isAppsLoading: action.payload };
    },
    setAppsRefreshing(state, action: PayloadAction<boolean>) {
      return { ...state, isAppsRefreshing: action.payload };
    },
    setApps(state, action: PayloadAction<AppType[]>) {
      return { ...state, appsArr: action.payload };
    },
    setServices(state, action: PayloadAction<ServiceType[]>) {
      return { ...state, servicesArr: action.payload };
    },
    setUsers(state, action: PayloadAction<UserType[]>) {
      return {
        ...state,
        coordinatorsArr: action.payload.filter((item) => item.IsCoordinator),
        executorsArr: action.payload.filter((item) => item.IsExecutor),
      };
    },
    setInitiators(state, action: PayloadAction<InitiatorsType[]>) {
      return { ...state, initiatorsArr: action.payload };
    },
    setInitiatorsArrRefreshing(state, action: PayloadAction<boolean>) {
      return { ...state, isInitiatorsArrRefreshing: action.payload };
    },
    setErrorMessage(state, action: PayloadAction<string>) {
      return { ...state, errorMessage: action.payload };
    },
  },
});

export const {
  setApps,
  setServices,
  setUsers,
  setInitiators,
  setErrorMessage,
  setAppsSliceIsLoading,
  setAppsRefreshing,
  setInitiatorsArrRefreshing,
} = AppsSlice.actions;

export default AppsSlice.reducer;

export const fetchApps = createAsyncThunk('apps/fetchApps', async (_, { dispatch }) => {
  await fetchPOSTData('Applications', dispatch, setApps, setErrorMessage);
});

export const refreshApps = createAsyncThunk('apps/fetchApps', (_, { dispatch }) => {
  dispatch(setAppsRefreshing(true));
  fetchDataPostSimple('Applications')
    .then((res) => dispatch(setApps(res)))
    .catch((err) => dispatch(setErrorMessage(err.message)))
    .finally(() => dispatch(setAppsRefreshing(false)));
});

export const fetchServices = createAsyncThunk('apps/fetchServices', async (_, { dispatch }) => {
  await fetchPOSTData('Services', dispatch, setServices, setErrorMessage);
});

// export const fetchUsers = createAsyncThunk('apps/fetchUsers', async (_, { dispatch }) => {
//   await fetchPOSTData('users', dispatch, setUsers, setErrorMessage);
// });

export const fetchInitiators = createAsyncThunk('apps/fetchInitiators', async (_, { dispatch }) => {
  await fetchPOSTData('Initiators', dispatch, setInitiators, setErrorMessage);
});
